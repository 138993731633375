import twConfig from '~/tailwind.config.js';

export function useScreenSize() {
  const sizes = twConfig.theme.screens;
  const transformedSizes = {};

  for (const key in sizes) {
    transformedSizes[key] = useMediaQuery(`(min-width: ${sizes[key]})`);
  }

  return transformedSizes;
}
