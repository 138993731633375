export default {
  current: 'currentColor',
  inherit: 'inherit',
  transparent: 'transparent',
  white: '#fff',
  black: '#000',

  //grays from tw base
  slate: {
    DEFAULT: '#64748b',
    50: '#f8fafc',
    100: '#f1f5f9',
    200: '#e2e8f0',
    300: '#cbd5e1',
    400: '#94a3b8',
    500: '#64748b',
    600: '#475569',
    700: '#334155',
    800: '#1e293b',
    900: '#0f172a',
    950: '#020617'
  },
  zinc: {
    DEFAULT: '#71717a',
    50: '#fafafa',
    100: '#f4f4f5',
    200: '#e4e4e7',
    300: '#d4d4d8',
    400: '#a1a1aa',
    500: '#71717a',
    600: '#52525b',
    700: '#3f3f46',
    800: '#27272a',
    900: '#18181b',
    950: '#09090b'
  },
  neutral: {
    DEFAULT: '#737373',
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#e5e5e5',
    300: '#d4d4d4',
    400: '#a3a3a3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
    950: '#0a0a0a'
  },
  stone: {
    DEFAULT: '#78716c',
    50: '#fafaf9',
    100: '#f5f5f4',
    200: '#e7e5e4',
    300: '#d6d3d1',
    400: '#a8a29e',
    500: '#78716c',
    600: '#57534e',
    700: '#44403c',
    800: '#292524',
    900: '#1c1917',
    950: '#0c0a09'
  },

  //FOREVER Gray
  gray: {
      DEFAULT: '#888',
      50: '#EFEFEF',
      100: '#E4E4E4',
      200: '#CDCDCD',
      300: '#B6B6B6',
      400: '#9F9F9F',
      500: '#888888',
      600: '#6C6C6C',
      700: '#505050',
      800: '#343434',
      900: '#181818',
      950: '#0A0A0A'
  },

  //NEW BRANDING
  ice: {
      DEFAULT: '#D2EBF0',
      50: '#f8fcfd',
      100: '#EDF7F9',
      200: '#e6f4f7',
      300: '#dff1f4',
      400: '#d9eef2',
      500: '#D2EBF0',
      600: '#a6d7e1',
      700: '#7ec5d4',
      800: '#52b2c5',
      900: '#3896a9',
      950: '#318494'
  },
  sage: {
      DEFAULT: '#BCD8C7',
      50: '#eff5f1',
      100: '#E4EFE8',
      200: '#daeae0',
      300: '#d0e4d8',
      400: '#c6decf',
      500: '#BCD8C7',
      600: '#9bc5ab',
      700: '#7CB392',
      800: '#5ea178',
      900: '#4a805f',
      950: '#355C45'
  },

  shell: {
      DEFAULT: '#EDE8E0',
      50: '#fcfcfb',
      100: '#FBFAF8',
      200: '#f8f6f3',
      300: '#f4f1ec',
      400: '#f1ede6',
      500: '#EDE8E0',
      600: '#ded5c6',
      700: '#d0c3ae',
      800: '#C19A6B',
      900: '#a97d47',
      950: '#896539'
  },
  salmon: {
      DEFAULT: '#D07063',
      50: '#fbf3f1',
      100: '#F5E0DC',
      200: '#ecc6c1',
      300: '#e3a9a1',
      400: '#d98d82',
      500: '#D07063',
      600: '#ca5f50',
      700: '#A6594F',
      800: '#995249',
      900: '#7b423a',
      950: '#5C312B'
  },

  charcoal: {
      DEFAULT: '#464547',
      50: '#f7f7f8',
      100: '#f0f0f1',
      200: '#E6E6E7',
      300: '#b5b4b6',
      400: '#7d7c7f',
      500: '#464547',
      600: '#3a393b',
      700: '#232324',
      800: '#1E1E1F',
      900: '#19191A',
      950: '#141414'
  },

  heroblue: {
      DEFAULT: '#007198',
      50: '#C3F0FF',
      100: '#A2E7FF',
      200: '#60D6FF',
      300: '#1EC5FF',
      400: '#00A2DA',
      500: '#007198',
      600: '#006284',
      700: '#00536F',
      800: '#00445B',
      900: '#003446',
      950: '#002C3C'
  },

  //PRIMARY FOREVER COLORS
  blue: {
      DEFAULT: '#2b91cf',
      50: '#eef6fb',
      100: '#daecf8',
      200: '#add5ee',
      300: '#83bfe4',
      400: '#55a7d9',
      500: '#2b91cf',
      600: '#2782ba',
      700: '#2273a5',
      800: '#1B5B83',
      900: '#144461',
      950: '#0D2C40'
  },

  green: {
      DEFAULT: '#7dc142',
      50: '#f4faf0',
      100: '#e8f4de',
      200: '#cce7b5',
      300: '#b3db90',
      400: '#97cd67',
      500: '#7dc142',
      600: '#71af3b',
      700: '#649d33',
      800: '#507E29',
      900: '#3D5F1F',
      950: '#294115'
  },

  orange: {
      DEFAULT: '#ed773a',
      50: '#fdf5f1',
      100: '#fceae0',
      200: '#f8ccb5',
      300: '#f5b18d',
      400: '#f19362',
      500: '#ed773a',
      600: '#e76927',
      700: '#e05a14',
      800: '#B14710',
      900: '#82340C',
      950: '#542207'
  },

  red: {
      DEFAULT: '#bf3030',
      50: '#F7DEDE',
      100: '#F2C9C9',
      200: '#E7A1A1',
      300: '#DD7878',
      400: '#D34F4F',
      500: '#BF3030',
      600: '#9E2828',
      700: '#7E2020',
      800: '#5D1717',
      900: '#3D0F0F',
      950: '#2C0B0B'
  },

  //Tailwind Palette
  twred: {
    DEFAULT: '#ef4444',
    50: '#fef2f2',
    100: '#fee2e2',
    200: '#fecaca',
    300: '#fca5a5',
    400: '#f87171',
    500: '#ef4444',
    600: '#dc2626',
    700: '#b91c1c',
    800: '#991b1b',
    900: '#7f1d1d',
    950: '#450a0a'
  },
  tworange: {
    DEFAULT: '#f97316',
    50: '#fff7ed',
    100: '#ffedd5',
    200: '#fed7aa',
    300: '#fdba74',
    400: '#fb923c',
    500: '#f97316',
    600: '#ea580c',
    700: '#c2410c',
    800: '#9a3412',
    900: '#7c2d12',
    950: '#431407'
  },
  amber: {
    DEFAULT: '#f59e0b',
    50: '#fffbeb',
    100: '#fef3c7',
    200: '#fde68a',
    300: '#fcd34d',
    400: '#fbbf24',
    500: '#f59e0b',
    600: '#d97706',
    700: '#b45309',
    800: '#92400e',
    900: '#78350f',
    950: '#451a03'
  },
  yellow: {
    DEFAULT: '#eab308',
    50: '#fefce8',
    100: '#fef9c3',
    200: '#fef08a',
    300: '#fde047',
    400: '#facc15',
    500: '#eab308',
    600: '#ca8a04',
    700: '#a16207',
    800: '#854d0e',
    900: '#713f12',
    950: '#422006'
  },
  lime: {
    DEFAULT: '#84cc16',
    50: '#f7fee7',
    100: '#ecfccb',
    200: '#d9f99d',
    300: '#bef264',
    400: '#a3e635',
    500: '#84cc16',
    600: '#65a30d',
    700: '#4d7c0f',
    800: '#3f6212',
    900: '#365314',
    950: '#1a2e05'
  },
  twgreen: {
    DEFAULT: '#22c55e',
    50: '#f0fdf4',
    100: '#dcfce7',
    200: '#bbf7d0',
    300: '#86efac',
    400: '#4ade80',
    500: '#22c55e',
    600: '#16a34a',
    700: '#15803d',
    800: '#166534',
    900: '#14532d',
    950: '#052e16'
  },
  emerald: {
    DEFAULT: '#10b981',
    50: '#ecfdf5',
    100: '#d1fae5',
    200: '#a7f3d0',
    300: '#6ee7b7',
    400: '#34d399',
    500: '#10b981',
    600: '#059669',
    700: '#047857',
    800: '#065f46',
    900: '#064e3b',
    950: '#022c22'
  },
  teal: {
    DEFAULT: '#14b8a6',
    50: '#f0fdfa',
    100: '#ccfbf1',
    200: '#99f6e4',
    300: '#5eead4',
    400: '#2dd4bf',
    500: '#14b8a6',
    600: '#0d9488',
    700: '#0f766e',
    800: '#115e59',
    900: '#134e4a',
    950: '#042f2e'
  },
  cyan: {
    DEFAULT: '#06b6d4',
    50: '#ecfeff',
    100: '#cffafe',
    200: '#a5f3fc',
    300: '#67e8f9',
    400: '#22d3ee',
    500: '#06b6d4',
    600: '#0891b2',
    700: '#0e7490',
    800: '#155e75',
    900: '#164e63',
    950: '#083344'
  },
  sky: {
    DEFAULT: '#0ea5e9',
    50: '#f0f9ff',
    100: '#e0f2fe',
    200: '#bae6fd',
    300: '#7dd3fc',
    400: '#38bdf8',
    500: '#0ea5e9',
    600: '#0284c7',
    700: '#0369a1',
    800: '#075985',
    900: '#0c4a6e',
    950: '#082f49'
  },
  twblue: {
    DEFAULT: '',
    50: '#eff6ff',
    100: '#dbeafe',
    200: '#bfdbfe',
    300: '#93c5fd',
    400: '#60a5fa',
    500: '#3b82f6',
    600: '#2563eb',
    700: '#1d4ed8',
    800: '#1e40af',
    900: '#1e3a8a',
    950: '#172554'
  },
  indigo: {
    DEFAULT: '#6366f1',
    50: '#eef2ff',
    100: '#e0e7ff',
    200: '#c7d2fe',
    300: '#a5b4fc',
    400: '#818cf8',
    500: '#6366f1',
    600: '#4f46e5',
    700: '#4338ca',
    800: '#3730a3',
    900: '#312e81',
    950: '#1e1b4b'
  },
  violet: {
    DEFAULT: '#8b5cf6',
    50: '#f5f3ff',
    100: '#ede9fe',
    200: '#ddd6fe',
    300: '#c4b5fd',
    400: '#a78bfa',
    500: '#8b5cf6',
    600: '#7c3aed',
    700: '#6d28d9',
    800: '#5b21b6',
    900: '#4c1d95',
    950: '#2e1065'
  },
  purple: {
    DEFAULT: '#a855f7',
    50: '#faf5ff',
    100: '#f3e8ff',
    200: '#e9d5ff',
    300: '#d8b4fe',
    400: '#c084fc',
    500: '#a855f7',
    600: '#9333ea',
    700: '#7e22ce',
    800: '#6b21a8',
    900: '#581c87',
    950: '#3b0764'
  },
  fuchsia: {
    DEFAULT: '#d946ef',
    50: '#fdf4ff',
    100: '#fae8ff',
    200: '#f5d0fe',
    300: '#f0abfc',
    400: '#e879f9',
    500: '#d946ef',
    600: '#c026d3',
    700: '#a21caf',
    800: '#86198f',
    900: '#701a75',
    950: '#4a044e'
  },
  pink: {
    DEFAULT: '#ec4899',
    50: '#fdf2f8',
    100: '#fce7f3',
    200: '#fbcfe8',
    300: '#f9a8d4',
    400: '#f472b6',
    500: '#ec4899',
    600: '#db2777',
    700: '#be185d',
    800: '#9d174d',
    900: '#831843',
    950: '#500724'
  },
  rose: {
    DEFAULT: '#f43f5e',
    50: '#fff1f2',
    100: '#ffe4e6',
    200: '#fecdd3',
    300: '#fda4af',
    400: '#fb7185',
    500: '#f43f5e',
    600: '#e11d48',
    700: '#be123c',
    800: '#9f1239',
    900: '#881337',
    950: '#4c0519'
  },

  //text convenience
  text: {
      muted: '#7d7c7f',
      DEFAULT: '#464547',
      light: '#646366',
      dark: '#232324'
  },

  //avatar legacy colors
  //note: blue and green above provide 2 of these.
  artisan: {
    700: '#1c87b7'
  },
  historian: {
    700: '#49a84a'
  },
  services: {
    700: '#2d8e97'
  },
  print: {
    700: '#3c9e79'
  },
  ambassador: {
    DEFAULT: '#a4314c',
    700: '#912b43'
  },
  p2p: {
    DEFAULT: '#f89406'
  }

};
