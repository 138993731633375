<template>
  <u-input
    v-model="q"
    v-bind="$attrs"
    :placeholder="placeholder"
    size="sm"
    autocomplete="off"
    icon="i-ri-search-line"
    :ui="searchConfig"
    :loading="loading"
    @keyup.enter="onSearch"
  >
    <template #trailing>
      <u-button
        v-show="q && q !== ''"
        color="gray"
        variant="link"
        :icon="COMMON_ICONS.close"
        :padded="false"
        @click="clear"
      />
    </template>
  </u-input>
</template>

<script setup>
  const props = defineProps({
    modelValue: String,
    placeholder: {
      type: String,
      default: 'Search'
    },
    loading: Boolean
  });

  const emit = defineEmits(['update:modelValue', 'search', 'clear']);

  const q = defineModel();

  function clear() {
    q.value = '';
    emit('clear')
  }

  function onSearch() {
    emit('search', {q: q.value});
  }

  const searchConfig = {
    wrapper: 'w-full sm:w-auto',
    trailing: {
      padding: {
        sm: 'pe-6'
      }
    },
    icon: {
      trailing: {
        pointer: '',
        padding: {
          sm: 'px-1.5'
        }
      }
    }
  }
</script>
