import {storeToRefs} from 'pinia';

export function useUserContext({entity}) {
  const ENTITY_VIEW_CONTEXTS = {
    owner: Symbol('owner'),
    user: Symbol('user'),
    manager: Symbol('manager'),
    friend: Symbol('friend'),
    nonuser: Symbol('nonuser')
  };

  const sessionStore = useSessionStore();
  const {currentUser, isAuthenticated} = storeToRefs(sessionStore);
  const useEntity = computed(() => (entity.value || entity));
  const entityViewContext = computed(() => {
    const hasEntityUser = useEntity.value?.user;

    if (hasEntityUser) {
      if (useEntity.value.user.id === currentUser.value?.id) {
        return ENTITY_VIEW_CONTEXTS.owner;
      } else if (useEntity.value.user.manager) {
        return ENTITY_VIEW_CONTEXTS.manager;
      } else if (useEntity.value.user.friend) {
        return ENTITY_VIEW_CONTEXTS.friend;
      } else if (isAuthenticated.value) {
        return ENTITY_VIEW_CONTEXTS.user;
      }
      return ENTITY_VIEW_CONTEXTS.nonuser;
    }
    return null;
  });

  //is the current user the owner of the entity
  const isEntityOwner = computed(() => entityViewContext.value === ENTITY_VIEW_CONTEXTS.owner);
  const isImpersonator = computed(() => currentUser.value?.isImpersonator);
  //either an entity owner or in a view where the user is authenticated but no entity exists (such as library, single file picker, etc)
  const isOwner = computed(() => isEntityOwner.value || (!useEntity.value && isAuthenticated.value));
  const canUserDelete = computed(() => (isOwner.value && !isImpersonator.value) || (isImpersonator.value && currentUser.value.impersonator.role === 'admin'));

  return {
    ENTITY_VIEW_CONTEXTS,
    isEntityOwner,
    canUserDelete
  };
}
