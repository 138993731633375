/**
 * @description
 * Fetches all records from a cursor paginated Core API
 *
 * @param entityName
 * @param dataFn example `cursor => agent.get('apiName', {query: {search, cursor}, route})`
 * @returns {Promise<*[]>}
 */
export async function fetchAllRecords(entityName, dataFn) {
  const records = [];
  let data = {};
  let cursor;

  //load all the data
  while (data[entityName]?.length === data.per_page) {
    const response = await dataFn(cursor);
    data = response.data || response;
    records.push(...data[entityName]);
    cursor = data.next_cursor;
  }

  return records;
}
